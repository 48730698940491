import { demandImportExcelApi, getDemandIssueListApi } from "@/api/request-modules/product";
import { awaitFunc } from "@/utils";

export default function useIteration() {
  // 需求批量导入
  const useDemandImportExcel = async (file: HTMLFormElement): Promise<{ success: boolean; url: string } | null> => {
    const [err, data]: [unknown, { success: boolean; url: string } | null] = await awaitFunc<HTMLFormElement, { success: boolean; url: string }>({
      asyncFunc: demandImportExcelApi,
      args: file
    });
    if (err || !data) return null;
    return data;
  };
  // 需求批量导入
  const useGetDemandIssueList = async (params: { product_id: number }): Promise<{ id: number; name: string } | null> => {
    const [err, data]: [unknown, { id: number; name: string } | null] = await awaitFunc<{ product_id: number }, { id: number; name: string }>({
      asyncFunc: getDemandIssueListApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  return {
    useDemandImportExcel,
    useGetDemandIssueList
  };
}
